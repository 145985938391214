<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <!--      <el-table-column type="selection" width="55"></el-table-column>-->

      <!--      <el-table-column :label="fields.id.label" :prop="fields.id.name" sortable="custom">-->
      <!--        <template slot-scope="scope">{{ presenter(scope.row, 'id') }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column
        width="150"
        :label="fields.type.label"
        :prop="fields.type.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'type')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.message.label"
        :prop="fields.message.name"
        sortable="custom"
      >
        <template slot-scope="scope">
            <p v-if="scope.row.type === 'Comment'">
              <span>
                {{ validExamName(scope.row, presenter(scope.row, 'examname')) }}
              </span>
              -
              <span class="useremail">
                {{ presenter(scope.row, 'useremail') }}
              </span>
              Quality:
              <span v-color="{ evaluation: presenter(scope.row, 'quality') }">
                {{ presenter(scope.row, 'quality') | checkValue }}
              </span>
              - Difficulty:
              <span v-color="{ evaluation: presenter(scope.row, 'difficulty') }">
                {{ presenter(scope.row, 'difficulty') | checkValue }}
              </span>
            </p>
            <p>
              {{ presenter(scope.row, 'message') | limitMessage }}
            </p>
        </template>
      </el-table-column>

      <!--      <el-table-column-->
      <!--        :label="fields.images.label"-->
      <!--        :prop="fields.images.name"-->
      <!--        align="center"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <app-list-item-image :value="presenter(scope.row, 'images')"></app-list-item-image>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/support-requests/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/support-requests/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { SupportRequestsModel } from '@/modules/support-requests/support-requests-model';
import { mapGetters, mapActions } from 'vuex';
import { SupportRequestsPermissions } from '@/modules/support-requests/support-requests-permissions';
import { i18n } from '@/i18n';

const { fields } = SupportRequestsModel;

export default {
  name: 'app-support-requests-list-table',

  data() {
    return {
      evaluationColors: ['red', 'red', 'orange', 'blue', 'green']
    };
  },

  directives: {
    color: {
      bind(el, binding, vnode) {
        const evaluation = binding.value.evaluation;
        if (evaluation && evaluation != -1) {
          el.style.color = vnode.context.evaluationColors[evaluation];
          el.style.fontWeight = '600';
        }
      }
    }
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  filters: {
    checkValue(value) {
      if (value && value != -1) return value + 1 + '/5';
      else return '__';
    },
    limitMessage(message) {
      if (message === "___")
        return ""
      else
        return message.length > 190 ? message.substring(0, 190)+'....' : message;
    }
  },

  computed: {
    ...mapGetters({
      rows: 'supportRequests/list/rows',
      count: 'supportRequests/list/count',
      loading: 'supportRequests/list/loading',
      pagination: 'supportRequests/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'supportRequests/destroy/loading',
      paginationLayout: 'layout/paginationLayout'
    }),

    hasPermissionToEdit() {
      return new SupportRequestsPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new SupportRequestsPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },

  },

  methods: {
    ...mapActions({
      doChangeSort: 'supportRequests/list/doChangeSort',
      doChangePaginationCurrentPage:
        'supportRequests/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'supportRequests/list/doChangePaginationPageSize',
      doMountTable: 'supportRequests/list/doMountTable',
      doDestroy: 'supportRequests/destroy/doDestroy'
    }),

    getMessageData(message, mData) {
      let value = JSON.parse(message)[mData];
      return value;
    },

    presenter(row, fieldName) {
      if (row.type == 'Comment') {
        if (fieldName == 'type') {
          return row.type;
        } else {
          const response = this.getMessageData(row.message, fieldName)
          return response ? response : '___';  
        }
      } else {
        return SupportRequestsModel.presenter(row, fieldName);
      }
    },

    validExamName(row,examname) {
      if (examname === '___') {
        return this.getMessageData(row.message, 'exam');
      }
      return examname;
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning'
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    }
  }
};
</script>

<style scoped>
.useremail {
  margin-right: 26px ;
}
</style>
