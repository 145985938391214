<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.supportRequests.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.supportRequests.list.title"
        ></app-i18n>
      </h1>

      <app-support-requests-list-toolbar></app-support-requests-list-toolbar>
      <app-support-requests-list-filter></app-support-requests-list-filter>
      <app-support-requests-list-table></app-support-requests-list-table>
    </div>
  </div>
</template>

<script>
import SupportRequestsListFilter from '@/modules/support-requests/components/support-requests-list-filter.vue';
import SupportRequestsListTable from '@/modules/support-requests/components/support-requests-list-table.vue';
import SupportRequestsListToolbar from '@/modules/support-requests/components/support-requests-list-toolbar.vue';

export default {
  name: 'app-support-requests-list-page',

  components: {
    [SupportRequestsListFilter.name]: SupportRequestsListFilter,
    [SupportRequestsListTable.name]: SupportRequestsListTable,
    [SupportRequestsListToolbar.name]: SupportRequestsListToolbar,
  },
};
</script>

<style></style>
